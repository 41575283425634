import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Phone from '@material-ui/icons/Phone';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';

import { formatUrl } from '../utils';

const ColoredAvatar = styled(Avatar)`
  /* && {
    background-color: #1a60b2;
  } */
`;

const Contact = ({
  shopDetails: {
    name,
    address,
    phone_number: phoneNumber,
    facebook,
    instagram,
  },
}) => {
  const { formatMessage: f } = useIntl();
  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom align="center">
        {f({ id: 'bookingContact' })}
      </Typography>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <ColoredAvatar>
              <LocationOnIcon />
            </ColoredAvatar>
          </ListItemAvatar>
          {address ? (
            <ListItemText
              primary={name}
              secondary={
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {`${address.address_line_1 || ''} ${address.address_line_2 ||
                    ''} ${address.town || ''} ${address.postal_code || ''}`}
                </Typography>
              }
            />
          ) : null}
        </ListItem>
        {phoneNumber ? (
          <ListItem alignItems="center">
            <ListItemAvatar>
              <ColoredAvatar>
                <Phone />
              </ColoredAvatar>
            </ListItemAvatar>
            <ListItemText primary={phoneNumber} />
          </ListItem>
        ) : null}
        {facebook ? (
          <ListItem alignItems="center">
            <ListItemAvatar>
              <ColoredAvatar>
                <Facebook />
              </ColoredAvatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <a
                  href={formatUrl(facebook)}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ overflowWrap: 'break-word' }}
                >
                  {facebook.replace('https://www.facebook.com/', '')}
                </a>
              }
            />
          </ListItem>
        ) : null}
        {instagram ? (
          <ListItem alignItems="center">
            <ListItemAvatar>
              <ColoredAvatar>
                <Instagram />
              </ColoredAvatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <a
                  href={formatUrl(
                    instagram.indexOf('instagram.com') > 1
                      ? instagram
                      : `instagram.com/${instagram.replace('@', '')}`,
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ overflowWrap: 'break-word' }}
                >
                  @
                  {instagram.indexOf('instagram.com') > 1
                    ? instagram.split('?')[0].split('instagram.com/')[1]
                    : instagram.replace('@', '')}
                </a>
              }
            />
          </ListItem>
        ) : null}
      </List>
    </Container>
  );
};

export default Contact;
