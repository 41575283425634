import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import {
  Typography,
  Grid,
  Box,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const OpeningHoursText = styled(Typography).attrs((props) => ({
  variant: 'body1',
}))`
  && {
    white-space: nowrap;
  }
`;

const ColoredAvatar = styled(Avatar)`
  /* && {
    background-color: #1a60b2;
  } */
`;

const OpeningHours = ({ openingHours }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom align="center">
        {f({ id: 'bookingSettingsOpening' })}
      </Typography>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <ColoredAvatar>
              <AccessTimeIcon />
            </ColoredAvatar>
          </ListItemAvatar>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="body1">
                {f({ id: 'bookingOpeningHoursMonday' })}
              </Typography>
              <Typography variant="body1">
                {f({ id: 'bookingOpeningHoursTuesday' })}
              </Typography>
              <Typography variant="body1">
                {f({ id: 'bookingOpeningHoursWednesday' })}
              </Typography>
              <Typography variant="body1">
                {f({ id: 'bookingOpeningHoursThursday' })}
              </Typography>
              <Typography variant="body1">
                {f({ id: 'bookingOpeningHoursFriday' })}
              </Typography>
              <Typography variant="body1">
                {f({ id: 'bookingOpeningHoursSaturday' })}
              </Typography>
              <Typography variant="body1">
                {f({ id: 'bookingOpeningHoursSunday' })}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="right">
                {openingHours &&
                  Object.keys(openingHours).map(
                    (key, index) =>
                      openingHours[key] && (
                        <OpeningHoursText variant="body1" key={key}>
                          {openingHours[key].open || openingHours[key].close
                            ? `${openingHours[key].open} - ${openingHours[key].close}`
                            : f({ id: 'bookingOpeningHoursClosed' })}
                        </OpeningHoursText>
                      ),
                  )}
              </Box>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Container>
  );
};

export default OpeningHours;
