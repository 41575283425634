import React from 'react';

import { Typography, Box, Link } from '@material-ui/core';

const BookingFooter = () => {
  return (
    <Box>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://barbercloud.net/">
          Barbercloud
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
};

export default BookingFooter;
